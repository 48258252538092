<template>
  <div class="py-6 w-full px-3">
    <h1 class="text-xl text-left font-bold flex mb-0 -mt-2">
      Permissions
    </h1>
    <div class="w-full h-0 p-0 mb-4" style="border: 0.2px solid #d6dbe4" />
    <div v-if="!showError">
    <Card>
      <Tabs
        class="mt-2 mx-2"
        :tabs="tabsItems"
        :active-tab="activeTab"
        @currentTab="activeTab = $event"
        :border="true"
      />
    </Card>
    <JobLevel v-if="activeTab == 'By Job Levels'" />
    <Employees v-if="activeTab == 'By Employees' " />
    </div>
    <ErrorPage v-else />
  </div>
</template>

<script>
import ErrorPage from "@/modules/Admin/error403";

export default {
  components: {
    Tabs: () => import("@scelloo/cloudenly-ui/src/components/tab"),
    Card: () => import("@/components/Card"),
    Employees: () => import("./employees"),
    JobLevel: () => import("./jobLevels"),
    ErrorPage
  },
  data() {
    return {
      activeTab: "By Job Levels",
      showDescription: false,
      showError: false,
      kpaModal: false,
      tabsItems: ["By Job Levels", "By Employees"]
    };
  },
  async mounted(){
    try {
      await this.$handlePrivilege("permissionHR", "accessPermissionSettings");
      this.showError = false
    } catch (error) {
      this.showError = true
    }
  }
};
</script>

<style>
.border__dashed {
  height: 0px;
  margin: 18px 0px;
  border: 1px dashed #878e99;
}
</style>
